.title-outer-layout {
  display: flex;
  padding: 10px 72px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: rgba(0, 0, 0, 0.85);
}

.title-text {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title-button-outer-layout {
  display: flex;
  height: 40px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.import-button {
  width: 82px;
  height: 35px;
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}

.create-button {
  width: 82px;
  height: 35px;
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-6, #05539b);
  background: var(--primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.outer-content-layout {
  display: flex;
  padding: 0px 72px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  height: calc(100% - 102px);
}

.outer-table-layout {
  display: flex;
  height: 90%;
  padding: 10px 0px;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  background: #fff;
}

.name-column {
  width: 759px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
}

.date-created-column {
  width: 179px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
}

.last-modified-column {
  width: 179px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
}

.action-column {
  width: 179px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  font-family: Inter;
}

.custom-modal-title {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--neutral-1, #fff);
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.custom-modal-title-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.custom-modal {
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 0px;
}

.custom-modal-content {
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
  padding: 0px 36px 25px 0px;
}

.custom-modal-footer {
  display: flex;
  align-items: flex-start;
  gap: 45px;
  align-self: stretch;
  padding: 0px 36px;
}

.custom-cancel-button {
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.custom-delete-button {
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 2px;
  border: 1px solid var(--dust-red-4, #ff7875);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--dust-red-4, #ff7875);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.download-popup-content-outer-layout{
  margin: 10px 0px;
  width:350px;
}

.download-popup-download-button-outer-layout{
  margin:20px 0px;
  display:flex
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
}
.loader-container .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-left: 10px;
}