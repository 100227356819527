.model-layout {
  display: flex;
  top: 3%;
  left: 15%;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px;
  background: var(--Conditional-pop-over, #fff);
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  padding-bottom: 0px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
  word-wrap: normal;
}

.modal-title-overlay {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Neutral-1, #fff);
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.modal-title-text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.model-content-overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.model-graph-plot-outer-layout {
  width: 700px;
  height: 520px;
  position: relative;
}

.model-graph-plot-area-layout {
  height: 100%;
  width: 100%;
  position: absolute;
}

.model-content-sidebar {
  display: flex;
  width: 296px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background: #f4f4f4;
}

.sidebar-content-outer-layout {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.content-element-layout {
  display: flex;
  padding-bottom: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
}

.content-element-text {
  flex: 1 0 0;
  color: var(--Text-Primary, #303030);
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
}

.content-element-button {
  display: flex;
  padding: 0px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: dodgerblue;
  border-color: dodgerblue;
}

.content-element-label {
  color: var(--Text-Secondary, rgba(0, 0, 0, 0.4));
  text-align: center;
  font-family: Segoe UI;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.24px;
  white-space: nowrap;
}

.custom-footer-outer-layout {
  display: flex;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
}

.custom-footer-button-outer-layout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.custom-footer-ok-button {
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--Primary-6, #05539b);
  background: var(--Primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.new-model-graph-plot-area-outer-layout {
  width: 700px;
  height: 550px;
  position: relative;
}

.new-model-graph-plot-area {
  height: 100%;
  width: 100%;
  position: absolute;
}

.input-number-style {
  background-color: #f4f4f4;
}

.content-element-units-label {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
  word-break: normal;
}

.tooltip {
  position: absolute;
  background-color: rgb(19, 17, 17);
  color: white;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: -100%;
  right: 0%;
  text-transform: capitalize;
}

.length-input-outer-layout {
  display: flex;
  padding-bottom: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

}