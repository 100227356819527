.header {
  display: flex;
  height: 42px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background: #004ca6;
}
.header-logo-layout {
  display: flex;
  align-items: center;
  gap: 7.68px;
}

.header-logo-text {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px; /* 137.5% */
  text-transform: capitalize;
}
.header-toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon-wrapper {
  display: flex;
  padding: 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: rgba(0, 0, 0, 0);
}

.question-circle-outline {
  width: 24px;
  height: 24px;
  color: #ffffff;
}

.header-user-icon-layout {
  display: flex;
  padding: 0px 12px;
  align-items: center;
  align-self: stretch;
  margin-top: 10px;
}
.dropdown-menu {
  display: flex;
  width: 254px;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
}
.dropdown-menu-user-name {
  cursor: text;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #f4f4f4;
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px; /* 135.714% */
}
.dropdown-menu-item {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.view-profile-custom-model {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 2px;
}

.view-profile-custom-model .ant-modal-content {
  padding: 0px;
  border-radius: 0px;
  width: 333px;
}

.view-profile-model-title {
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--Neutral-1, #fff);

  /* border & divider/divider ↓ */
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
}

.view-profile-model-content {
  display: flex;
  padding: 12px 36px 36px 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 45px;
}

.view-profile-model-content-outerlayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}

.view-profile-model-content-text {
  display: flex;
  height: 32px;
  align-items: flex-start;
}
.view-profile-content-label {
  color: var(--Text-Secondary, rgba(0, 0, 0, 0.4));
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.24px;
}
.view-profile-content-value {
  display: flex;
  padding-left: 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--Text-Primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
}
.view-profile-delete-button {
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Dust-Red-4, #ff7875);
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--Dust-Red-4, #ff7875);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
