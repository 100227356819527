.loader-container {
  display: flex;
  justify-content: center;
  align-items: flex-center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
  word-wrap: normal;
  color: #004f9b;
}

.generated-model-outer-layout {
  width: 698px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px;
}

.generated-graph-content-layout {
  display: flex;
  flex-direction: row;
  align-items: start;
  align-self: stretch;
}

.generated-model-navbar-layout {
  display: flex;
  justify-content: space-evenly;
  width: 150px;
}

.generated-model-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Segoe UI";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.generated-model-body-content-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  flex: 1;
  width: 183px;
  margin: top 12px;
}

.generated-model-download-button-outer-layout {
  display: flex;
  padding: 24px 24px 0px 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.generated-modal-download-button {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid var(--Primary-6, #05539b);
  background: var(--Primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.generated-model-graph-plot-area-outer-layout {
  width: 720px;
  height: 612px;
}

.generated-model-graph-plot {
  width: 720px;
  height: 550px;
}

.generated-model-footer-outer-layout {
  display: flex;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.generated-model-footer-button-outer-layout {
  display: flex;
  padding: 0px 24px;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.generated-model-footer-button-content-layout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 120px;
  flex-direction: row;
}

.adjust-slider-outer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
}

.adjust-slider-label-text {
  align-self: stretch;
  color: var(--Text-Primary, #303030);
  font-family: "Segoe UI";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
  white-space: nowrap;
}

.generated-model-slider {
  display: flex;
  padding: 2px 0px;
  align-items: center;
  gap: 78px;
  border-radius: 100px;
  background: var(--Primary-3, #91d5ff);
}