
.header-outer-layout {
  display: flex;
  padding: 2px 28px;
  justify-content: space-between;
  align-items: center;
  background: #1a1a1a;
}

.header-left-side-outer-layout {
  display: flex;
  align-items: center;
  gap: 8px;
}

.back-button-arrow {
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 1);
}

.edit-outline-icon {
  width: 16px;
  height: 16px;
  color: rgba(255, 255, 255, 1);
}

.edit-outline-icon.disabled {
  cursor: not-allowed;
  opacity: .5
}

.home-outlined {
  width: 18px;
  height: 18px;
  color: rgba(255, 255, 255, 1);
}

.header-text-style {
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 0px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.28px;
  white-space: nowrap;
}

.header-file-name-text-style {
  color: rgba(255, 255, 255, 0.7);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.28px;
  background-color: #1a1a1a;
  border: none;
}

.header-right-side-outer-layout {
  display: flex;
  align-items: center;
  gap: 19px;
}

.import-image-button {
  color: #000;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  border: none;
  width: 115px;
  border-radius: unset;
}

.import-image-button:hover {
  color: #000;
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.28px;
  border: none;
  background: transparent;
}

.ant-btn-default:disabled {
  background-color: white;
  color: darkgray;
}

.menu-items-style1 {
  padding: 0px;
  margin: 0px;
}

.menu-items-style {
  display: flex;
  padding: 0px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  background: white;
}

.ant-dropdown-menu-submenu-title {
  padding: 0px;
}

.save-button {
  display: flex;
  width: 58px;
  height: 28px;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--primary-6, #05539b);
  background: var(--primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.24px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
}

.loader-container .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-left: 10px;
}

.refrence-image-text {
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
}

.refrence-image-text.disabled {
  cursor: not-allowed;
  opacity: .5;

}