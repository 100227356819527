.home-screen-layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background: #f0f2f5;
  overflow: hidden;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
}

.loader-container .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-left: 10px;
}