* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

.box-outer-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 80px 0px 120px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  background: #f0f2f5;
}

.box-container {
  display: flex;
  width: 600px;
  padding: 56px 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
  margin: auto;
  margin-top: 0px;
}

.box-title-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -6px;
  align-self: stretch;
}
.box-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.box-title-image {
  background: 50% / cover no-repeat;
}
.box-title-imagesub-text {
  align-self: stretch;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}
.box-title-text {
  align-self: stretch;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.36px;
}
.box-title-subtext {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}

.form-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}

.input-field-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.email-label-text {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.28px;
}

.input-field {
  display: flex;
  padding: 5px 12px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.error-input {
  border: 1px solid #ff4d4f; /* Red border for error state */
}

.submit-button-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.submit-button {
  height: 40px;
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #004f9b;
  background: #004f9b;

  /* drop-shadow/button-primary */
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.box-footer-text {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.navigation-link {
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--primary-6, #05539b);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.form-links-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}
.timer-text {
  color: rgba(0, 0, 0, 0.45);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.28px;
}
.text {
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.resend-button-outer-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
}

.loader-container .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-left: 10px;
}
