.sensor-outer-layout {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-5, #d9d9d9);
}

.sensor-field-name-layout {
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.sensor-field-name {
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
  flex: 1 0 0px;
  text-align: left;
}

.input-fields-value {
  background: #f4f4f4;
}

.buttons-outer-layout {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.sensor-obstacle-fields-outer-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #f4f4f4;
}

.sensor-obstacle-fields-column-layout {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
}

.fields-data-outer-layout {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 8px;
}

.sensor-field-label-light {
  color: var(--text-secondary, rgba(0, 0, 0, 0.4));
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.24px;
  word-break: keep-all;
}

.sensor-field-label-value {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
}

.fields-row-outer-layout {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.sensor-field-label-name {
  display: flex;
  padding: 6px 12px 4px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
  flex: 1 0 0;
}