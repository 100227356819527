.sidebar-outer-layout {
  display: flex;
  width: 500px;
  height: 600px;
  flex-direction: column;
  align-items: flex-start;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  height: calc(100% - 70px);
}

.side-bar-top-nav-layout {
  display: flex;
  padding: 16px 0px 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.tab-bar-wrapper-outer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
}

.tab-bar-wrapper {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.tabs-group {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 32px;
  align-self: stretch;
}

.tab-fields-area {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  width: 490px;
}

.custom-scroll-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.custom-scroll-container::-webkit-scrollbar {
  width: 8px;
}

.custom-scroll-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.tab-form-area {
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 450px;
  margin: auto;
}

.evalution-area-text-outer-layout {
  display: flex;
  padding: 16px 12px 12px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.evalution-text {
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
  text-transform: uppercase;
}

.fields-outer-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.label-text {
  display: flex;
  padding: 16px 12px 12px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
}

.fileds-value-outer-layout {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.value-layout {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 10px;
}

.value-label {
  display: flex;
  padding: 0px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  color: var(--text-secondary, rgba(0, 0, 0, 0.4));
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.24px;
}

.value-text {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
}

.add-sensor-obstacle-button {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background: #fff;
  display: flex;
  padding: 4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
}

.sensor-outer-layout {
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-5, #d9d9d9);
}

.buttons-outer-layout {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
}

.sensor-layout {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.sensor-name {
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
}

.simulator-graph-area {
  display: flex;
  padding: 16px 10px 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  background: #fff;
  width: 450px;
}

.simulation-field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.simulation-field-title {
  display: flex;
  padding: 16px 12px 4px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.06px;
  flex: 1 0 0;
}

.checkbox-fields {
  color: var(--text-primary, #303030);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border: 1px solid var(--primary-6, #05539b);
  background: var(--primary-6, #05539b);
}

.ant-input-number {
  padding: 0px;
}