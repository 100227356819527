.title-outer-layout {
  display: flex;
  padding: 10px 72px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  color: rgba(0, 0, 0, 0.85);
}

.title-text {
  color: rgba(0, 0, 0, 0.85);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.content-outer-layout {
  display: flex;
  height: 678px;
  padding: 94px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
}

.content-layout {
  display: flex;
  padding: 0px 72px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.content {
  display: flex;
  width: 560px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.text-outer-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.heading-text {
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.heading-sub-text {
  color: var(--Black, #000);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.create-button {
  width: 82px;
  height: 35px;
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-6, #05539b);
  background: var(--primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.settingnotexist-buttons-outer-layout {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
}

.import-button {
  width: 82px;
  height: 35px;
  display: flex;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-5, #d9d9d9);
  background: var(--Neutral-1, #fff);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}