.graph-plot-outer-layout {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.graph-area-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background-color: #F3F9FF;
  height: calc(100vh - 86px);
}

.graph-plot-area-layout {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.graph-plot-side-bar-outer-layout {
  height: 100%;
}

.plot-area {
  width: 640px;
  height: 540px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  margin: auto;
}

.generate-button-layout {
  display: flex;
  width: 500px;
  padding: 19px 8px;
  flex-direction: row;
  position: fixed;
  right: 0px;
  bottom: 0px;
  background: #f4f4f4;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.generate-button {
  display: flex;
  width: 180px;
  padding: 6.4px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--primary-6, #05539b);
  background: var(--primary-6, #05539b);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
  color: var(--character-primary-inverse, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}



.screen-disabled {
  pointer-events: none;
  opacity: 0.5;
  /* Adjust opacity to visually indicate the disabled state */
  cursor: not-allowed;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  color: #004f9b;
}
.loader-container .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-left: 10px;
}

.tooltip {
  position: absolute;
  background-color: rgb(19, 17, 17);
  color: white;
  padding: 5px;
  border-radius: 3px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: -100%;
  right: 0%;
  white-space: nowrap;
}

.tooltip::before {
  content: "";
  position: absolute;
  top: 100%;
  /* Position the tip below the tooltip */
  left: 50%;
  /* Center the tip horizontally */
  margin-left: -5px;
  /* Adjust based on the size of the tip */
  border-width: 5px;
  /* Adjust the size of the tip */
  border-style: solid;
  border-color: rgb(19, 17, 17) transparent transparent transparent;
  /* Adjust color as needed */
  z-index: 2;
  /* Ensure the tip is above the tooltip */
}


/* Show the tooltip on hover */
.fa:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.fa-fa-button {
  position: relative;

}